import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ContactImage from '../assets/images/contact-image.webp';

/**
 * Page de contact complète avec design, SEO et logique de formulaire.
 */
function Contact() {
  // States pour la gestion du formulaire et du RGPD
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: ''
  });
  const [rgpdAccepted, setRgpdAccepted] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Gestion des champs du formulaire
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Gestion de l'acceptation RGPD
  const handleRgpdChange = (e) => {
    setRgpdAccepted(e.target.checked);
  };

  // Envoi du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('');
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('/api/contact', formData);
      if (response.data.success) {
        setStatusMessage('Votre message a bien été envoyé !');
        // Réinitialisation du formulaire et de la case RGPD
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          message: ''
        });
        setRgpdAccepted(false);
      } else {
        setError('Une erreur est survenue, merci de réessayer plus tard.');
      }
    } catch (err) {
      setError('Une erreur est survenue, merci de réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  // Désactivation du bouton d'envoi si conditions non remplies
  const isSubmitDisabled =
    loading ||
    !formData.firstName.trim() ||
    !formData.lastName.trim() ||
    !formData.email.trim() ||
    !formData.message.trim() ||
    !rgpdAccepted;

  // JSON-LD pour la page de contact
  const contactPageJson = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    url: 'https://niceevents.be/contact',
    mainEntity: {
      '@type': 'Organization',
      name: 'Nice Events Sonorisation',
      url: 'https://niceevents.be',
      logo: 'https://niceevents.be/images/logo.jpg',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+32475770204',
        email: 'info@niceevents.be',
        contactType: 'Service client',
        areaServed: 'BE',
        availableLanguage: ['French']
      }
    }
  };

  // JSON-LD pour l'organisation et ses horaires
  const organizationWithOpeningHours = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Nice Events Sonorisation',
    url: 'https://niceevents.be',
    logo: 'https://niceevents.be/images/logo.jpg',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+32475770204',
        contactType: 'customer service',
        areaServed: ['BE', 'FR'],
        availableLanguage: ['French']
      }
    ],
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday'],
        opens: '10:00',
        closes: '22:00'
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Friday',
        opens: '10:00',
        closes: '19:00'
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '13:00',
        closes: '19:00'
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '13:00',
        closes: '22:00'
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Contactez-nous | Nice Events Sonorisation en Belgique et France</title>
        <meta
          name="description"
          content="Contactez l'équipe de Nice Events Sonorisation pour un devis gratuit ou des informations sur nos prestations DJ, quiz, karaoké et matériel événementiel."
        />
        <link rel="canonical" href="https://niceevents.be/contact" />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Contactez-nous | Nice Events Sonorisation"
        />
        <meta
          property="og:description"
          content="Besoin d'un DJ, d'un quiz interactif ou d'un karaoké ? Contactez-nous pour un devis gratuit en Belgique et en France."
        />
        <meta property="og:url" content="https://niceevents.be/contact" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://niceevents.be/images/og-image.jpg"
        />

        {/* JSON-LD : ContactPage */}
        <script type="application/ld+json">
          {JSON.stringify(contactPageJson)}
        </script>

        {/* JSON-LD : Organization + Horaires */}
        <script type="application/ld+json">
          {JSON.stringify(organizationWithOpeningHours)}
        </script>
      </Helmet>

      {/* Header / Bannière */}
      <div
        className="contact-banner position-relative text-center text-light d-flex align-items-center justify-content-center mb-5"
        style={{ minHeight: '300px' }}
      >
        <img
          src={ContactImage}
          alt="Bannière de la page contact"
          className="img-fluid w-100"
          style={{ height: '400px', objectFit: 'cover' }}
        />
        <div className="overlay-animated" />
        <div
          className="position-absolute top-50 start-50 translate-middle hero-text-effect"
          style={{ zIndex: 2 }}
        >
          <h1 className="display-3 fw-bold">Contactez-nous</h1>
          <p className="lead">
            Des questions pour votre prochain événement ?<br />
            Notre équipe est là pour vous répondre dans les plus brefs délais
          </p>
        </div>
      </div>

      {/* Contenu principal */}
      <div className="container py-5 fade-in">
        <div className="row">
          {/* Coordonnées */}
          <div className="col-md-6 mb-4">
            <h2 className="mb-3">Informations de contact</h2>
            <p>
              <strong>Téléphone :</strong>{' '}
              <a href="tel:+32475770204" className="text-decoration-none">
                +32 475 77 02 04
              </a>
            </p>
            <p>
              <strong>Email :</strong>{' '}
              <a
                href="mailto:infot@niceevents.be"
                className="text-decoration-none"
              >
                info@niceevents.be
              </a>
            </p>
            <p className="mb-4">
              <strong>Horaires :</strong>
              <br />
              <span>Lundi au jeudi : 10h à 22h</span>
              <br />
              <span>Vendredi : 10h à 19h</span>
              <br />
              <span>Samedi : 13h à 19h</span>
              <br />
              <span>Dimanche : 13h à 22h</span>
            </p>

            <p>
              Vous pouvez également nous contacter via le{' '}
              <Link to="/quote-form" className="text-decoration-none">
                formulaire de devis rapide
              </Link>{' '}
              si vous souhaitez en savoir plus sur nos prestations DJ, quiz
              interactifs ou karaoké.
            </p>
          </div>

          {/* Formulaire de contact */}
          <div className="col-md-6">
            <h2 className="mb-3">Envoyez-nous un message</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  Prénom *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="Votre prénom"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Nom *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Votre nom"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Téléphone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Votre numéro de téléphone"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Adresse e-mail *
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="exemple@domain.com"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Votre message *
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Décrivez votre projet ou posez-nous vos questions..."
                ></textarea>
              </div>

              {/* Case RGPD */}
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rgpdCheck"
                  checked={rgpdAccepted}
                  onChange={handleRgpdChange}
                  required
                />
                <label className="form-check-label" htmlFor="rgpdCheck">
                  J'accepte la{' '}
                  <Link
                    to="/privacy-policy"
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    politique de confidentialité
                  </Link>{' '}
                  *
                </label>
              </div>

              <p className="text-muted">* Champs obligatoires</p>

              {/* Messages de succès / erreur */}
              {statusMessage && (
                <div className="alert alert-success" role="alert">
                  {statusMessage}
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {/* Bouton d'envoi */}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitDisabled}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Envoi en cours...
                  </>
                ) : (
                  'Envoyer votre message'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Décor bas de page (facultatif) */}
      <svg
        className="section-decorator"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#d32f2f"
          fillOpacity="1"
          d="M0,256L60,224C120,192,240,128,360,112C480,96,600,128,720,138.7C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </>
  );
}

export default Contact;
